import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';

function ReportsTable() {
  const [data, setData] = useState([]);

  // Fetch data from the API
  useEffect(() => {
    fetch('https://eox0s4p1ve.execute-api.us-west-2.amazonaws.com/prod/reports')
      .then((response) => response.json())
      .then((data) => {
        data.forEach(d => {
          const date = new Date(d.submission_time_utc * 1000);
          d.submission_time_utc = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        })
        setData(data); // Assuming the API returns the list directly
      })
      .catch((error) => console.error('Error fetching data: ', error));
  }, []);

  // Define columns for react-table, updated for UID, OS, CPU, Speed
  const columns = useMemo(
    () => [
      {
        Header: 'UID',
        accessor: 'uid',
      },
      {
        Header: 'Submission Time',
        accessor: 'submission_time_utc',
      },
      {
        Header: 'OS',
        accessor: 'os',
      },
      {
        Header: 'CPU',
        accessor: 'cpu',
      },
      {
        Header: 'Platform',
        accessor: 'platform', // Adjusted to 'cpu'
      },      {
        Header: 'Sectors Analyzed',
        accessor: 'sectors', // Adjusted to 'cpu'
      },
      {
        Header: 'Plots',
        accessor: 'disks', // Adjusted to 'speed'
      },
      {
        Header: 'Min/Sector',
        accessor: 'speed', // Adjusted to 'speed'
      },
    ],
    []
  );

  // Use the useTable and useSortBy hooks to create and manage the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  // Render the table with sortable columns
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ReportsTable;
