import './App.css';
import ReportsTable from './ReportTable';

function App() {
  return (
    <div className="App">
        <h1>Plot Report</h1>
        <div id='hhw'>
          <hr />
          <p>To support this page please subscribe to my YT channel: <a href='https://www.youtube.com/channel/UCakvG7QQp4oL0Rtpiei1yKg' target='_blank'>Hake Hardware</a></p>
          <p>If you would like to contribute your speeds, check out <a href='https://github.com/hakehardware/plotreport' target='_blank'>my github</a> for an automated script.</p>
        </div>
        <div className="reportsTableContainer">
          <ReportsTable />
        </div>

    </div>
  );
}

export default App;
